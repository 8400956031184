import { Injectable, inject } from '@angular/core';
import {
  Router,Route,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';

import { AccountService } from '@app/_services';

@Injectable({ providedIn: 'root' })
export class AuthGaurd {
    constructor(private authService: AccountService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      const currentUser = this.authService.userValue;
      console.log('currentUser',' not known')
      if (currentUser) {
        // logged in so return true
        console.log('currentUser',currentUser)
        return true;
      }
  
      // not logged in so redirect to login page with the return url
       this.authService.logout();
      return false;
    }
}
