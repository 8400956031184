<h2 mat-dialog-title>Success Publishers Privacy Policy</h2>
<mat-dialog-content class="mat-typography">

    <p class="text-justify">Success Publishers, an online bookseller, values the privacy of its customers and is
        committed to protecting it. This Privacy Policy outlines how we collect, use, and safeguard your personal
        information when you visit our website or make purchases from us.
    </p>

    <h2 class="font-bold">Information We Collect</h2>

    <ol class="list-inside  list-disc text-justify">
        <li>Acceptance: By using our website or making a purchase, you agree to comply with these Terms & Conditions. If
            you do not agree with any part of these Terms, you must not use our website or purchase products from us.
        </li>

        <li>Eligibility: You must be at least 18 years old to make a purchase from Success Publishers. By placing an
            order, you represent that you are of legal age to enter into a binding contract.</li>
    </ol>
    <h2 class="font-bold">How We Use Your Information</h2>
    <p class="text-justify">
        We use the information we collect for various purposes, including:
    </p>
    <ol class="list-inside list-disc text-justify">
        <li>Processing and fulfilling your orders.
        </li>
        <li>
            Communicating with you about your orders, account, or inquiries.
        </li>
        <li>
            Providing customer support and assistance.
        </li>
        <li>
            Personalizing your shopping experience and recommending products that may interest you.
        </li>
        <li>
            Improving our website, products, and services.
        </li>
        <li>
            Preventing fraud and ensuring the security of our website and customers.
        </li>
        <li>
            Complying with legal obligations.</li>
    </ol>
    <h2 class="font-bold">Sharing Your Information</h2>
    <ol class="list-inside list-disc text-justify">
        <li>We may share your personal information with third-party service providers who assist us in conducting our
            business operations, such as payment processors, shipping companies, and marketing partners. We may also
            share your information when required by law or to protect our rights, property, or safety, or that of
            others.</li>
    </ol>
    <h2 class="font-bold">Your Choices</h2>
    <ol class="list-inside list-decimal text-justify">
        <li>You have the option to manage your preferences regarding the collection and use of your personal
            information. You can update your account settings, unsubscribe from marketing communications, and opt out of
            certain data collection practices.</li>
    </ol>
    <h2 class="font-bold">Security</h2>
    <ol class="list-inside list-disc text-justify">
        <li>We implement appropriate security measures to protect your personal information from unauthorized access,
            alteration, disclosure, or destruction. However, no method of transmission over the internet or electronic
            storage is 100% secure, and we cannot guarantee absolute security.
        </li>

    </ol>
    <h2 class="font-bold">Contact Us</h2>
    <ol class="list-inside list-disc text-justify">
        <li>If you have any questions, concerns, or requests regarding this Cookie Policy or our use of cookies, please
            contact us at info&#64;successpublishers.in</li>

    </ol>
    <p class="font-bold  text-justify">By continuing to use our website, you consent to the use of cookies as described
        in this Privacy Policy.</p>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Close</button>

</mat-dialog-actions>