<h2 mat-dialog-title>Success Publishers Cookies Policy </h2>
<mat-dialog-content class="mat-typography">

    <p class="text-justify">Success Publishers, an online bookseller, utilizes cookies and similar technologies to
        enhance your browsing experience, analyze website traffic, and personalize content and advertisements. This
        Cookie Policy explains what cookies are, how we use them, and your choices regarding their usage.</p>

    <h2 class="font-bold">What are Cookies?</h2>

    <ol class="list-inside  list-disc text-justify">
        <li> Cookies are small text files that are stored on your device (computer, tablet, smartphone) when you visit a
            website. They serve various purposes, such as remembering your preferences, analyzing website performance,
            and enabling certain website features.
        </li>


    </ol>
    <h2 class="font-bold">How We Use Cookies</h2>
    <ol class="list-inside list-disc text-justify">
        <li>Essential Cookies: These cookies are necessary for the proper functioning of our website. They enable core
            functionalities such as navigation, account authentication, and accessing secure areas of the website.
</li><li> 
            Performance Cookies: These cookies collect information about how visitors use our website, such as which
            pages are most popular, how long users spend on certain pages, and any error messages encountered. This
            helps us improve the performance and usability of our website.
        </li><li> 
            Functionality Cookies: These cookies allow the website to remember choices you make (such as language
            preferences) and provide enhanced, more personalized features.
        </li><li> 
             Advertising Cookies: We and our advertising partners may use cookies to deliver advertisements that are
            relevant to your interests. These cookies track your browsing behavior across websites and may be used to
            build a profile of your interests for targeted advertising purposes.

        </li>


    </ol>
    <h2 class="font-bold">Your Choices</h2>
    <ol class="list-inside list-disc text-justify">
        <li>You can control and manage cookies in various ways, including:
        </li><li> 
            Browser Settings: Most web browsers allow you to control cookies through their settings. You can typically
            find these settings in the "Options" or "Preferences" menu of your browser. However, please note that
            blocking or disabling certain types of cookies may impact the functionality of the website.
        </li><li> 
            Third-Party Tools: You can opt out of certain cookies by using third-party tools or services, such as those
            provided by advertising networks or cookie management platforms.
        </li><li> 
            Do Not Track: Some web browsers offer a "Do Not Track" (DNT) feature that sends a signal to websites
            indicating your preference regarding tracking and behavioral advertising. Our website does not currently
            respond to DNT signals.</li>


    </ol>
    <h2 class="font-bold">Changes to This Policy</h2>
    <ol class="list-inside list-decimal text-justify">
        <li>We may update this Cookie Policy from time to time to reflect changes in our practices or applicable laws.
            We encourage you to review this Policy periodically for any updates.
        </li>

    </ol>
    <h2 class="font-bold">Contact Us</h2>
    <ol class="list-inside list-decimal text-justify">
        <li>If you have any questions, concerns, or requests regarding this Cookie Policy or our use of cookies, please
            contact us at info&#64;successpublishers.in</li>

    </ol>
    <p class="font-bold  text-justify">By continuing to use our website, you consent to the use of cookies as described
        in this Cookie Policy.</p>


</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Close</button>

</mat-dialog-actions>