import { AuthGaurd } from './_helpers';
import { LayoutComponent } from './layouts/layout/layout.component';
import { VexRoutes } from '@vex/interfaces/vex-route.interface';

export const appRoutes: VexRoutes = [
  {
    path: 'login',
    loadComponent: () =>
      import('./pages/pages/auth/login/login.component').then(
        (m) => m.LoginComponent
      )
  },
  {
    path: 'register',
    loadComponent: () =>
      import('./pages/pages/auth/register/register.component').then(
        (m) => m.RegisterComponent
      )
  },
  {
    path: 'forgot-password',
    loadComponent: () =>
      import(
        './pages/pages/auth/forgot-password/forgot-password.component'
      ).then((m) => m.ForgotPasswordComponent)
  },{
    path: 'resetpassword',
    loadComponent: () =>
      import(
        './pages/pages/auth/reset-password/reset-password.component'
      ).then((m) => m.ResetPasswordComponent)
  },{
    path: 'verify',
    loadComponent: () =>
      import('./pages/pages/auth/verify-email/verify-token.component').then(
        (m) => m.VerifyTokenComponent
      )
  },
 
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadComponent: () =>
          import(
            './pages/books/browse-books.component'
          ).then((m) => m.BrowseBooksComponent)
        
      },
      {
        path: 'browsebooks',
        redirectTo: '/',
        pathMatch: 'full'
      },
      {
        path: 'viewbook/:id',
        loadComponent: () =>
          import(
            './pages/books/view-book.component'
          ).then((m) => m.ViewBookComponent)
      },
      {
        path: 'viewcart',
        loadComponent: () =>
          import(
            './pages/books/cart-view/cart-view.component'
          ).then((m) => m.CartViewComponent)
      },
      {
        path: 'checkout',
        loadComponent: () =>
          import(
            './pages/books/checkout/checkout.component'
          ).then((m) => m.CheckoutComponent)
      },
      {
        path: 'myorders',
        loadComponent: () =>
          import(
            './pages/customers/myorders/myorders.component'
          ).then((m) => m.MyordersComponent)
      },
      {
        path: 'credentials',canActivate: [AuthGaurd],
        loadComponent: () =>
          import(
            './pages/pages/auth/change-password/change-password.component'
          ).then((m) => m.ChangePasswordComponent)
      },
      {
        path: 'masters/publisher',canActivate: [AuthGaurd],
        loadComponent: () =>
        import(
          './pages/masters/publishers/publishers.component'
        ).then((m) => m.PublishersComponent)
      
      },
      {
        path: 'masters/author',canActivate: [AuthGaurd],
        loadComponent: () =>
        import(
          './pages/masters/authors/authors.component'
        ).then((m) => m.AuthorsComponent)
      
      },
      {
        path: 'masters/languages',canActivate: [AuthGaurd],
        loadComponent: () =>
        import(
          './pages/masters/languages/languages.component'
        ).then((m) => m.LanguagesComponent)
      
      },  {
        path: 'masters/booktypes',canActivate: [AuthGaurd],
        loadComponent: () =>
        import(
          './pages/masters/booktypes/booktypes.component'
        ).then((m) => m.BooktypesComponent)
      
      },  {
        path: 'masters/books',canActivate: [AuthGaurd],
        loadComponent: () =>
        import(
          './pages/masters/books/books.component'
        ).then((m) => m.BooksComponent)
      
      },  {
        path: 'masters/books/new',canActivate: [AuthGaurd],
        loadComponent: () =>
        import(
          './pages/masters/books/new-book/new-book.component'
        ).then((m) => m.NewBookComponent)
      
      },{
        path: 'masters/books/edit/:id',canActivate: [AuthGaurd],
        loadComponent: () =>
        import(
          './pages/masters/books/modify-book/modify-book.component'
        ).then((m) => m.ModifyBookComponent)
      
      }, {
        path: 'books/inventory',canActivate: [AuthGaurd],
        loadComponent: () =>
        import(
          './pages/masters/books/inventory/inventory.component'
        ).then((m) => m.InventoryComponent)
      
      }, {
        path: 'books/inventory/edit/:id',canActivate: [AuthGaurd],
        loadComponent: () =>
        import(
          './pages/masters/books/inventory/modify-inventory.component'
        ).then((m) => m.ModifyInventoryComponent)
      
      },
    /*  {
        path: 'masters/country',
        loadComponent: () =>
        import(
          './pages/masters/wallets/wallets-list.component'
        ).then((m) => m.WalletsListComponent)
      
      },
      {
        path: 'masters/author',
        loadComponent: () =>
        import(
          './pages/masters/platforms/platforms.component'
        ).then((m) => m.PlatformsComponent)
      
      },
    */
       
  /*    {
        path: 'staff/list',
        loadComponent: () =>
        import(
          './pages/staff/staff-master.component').then((m) => m.StaffMasterComponent)
      },
      {
        path: 'staff/roles/:id',
        loadComponent: () =>
        import(
          './pages/staff/roles-staff.component').then((m) => m.RolesStaffComponent)
      },
      {
        path: 'roles/master',
        loadComponent: () =>
        import(
          './pages/staff/roles-master.component').then((m) => m.RolesMasterComponent)
      },
     */
      {
        path: '',
        loadComponent: () =>
          import(
            './pages/dashboards/dashboard-analytics/dashboard-analytics.component'
          ).then((m) => m.DashboardAnalyticsComponent)
      },
      {
        path: '**',
        loadComponent: () =>
          import('./pages/pages/errors/error-404/error-404.component').then(
            (m) => m.Error404Component
          )
      }
    ]
  }
];
