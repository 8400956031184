import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Address, Orderplace, Payment, ResultModel } from '@app/_models';
  
import { CartItem } from '@app/_models/cart-item';
import { BehaviorSubject, Observable } from 'rxjs';


const baseUrl = '/api/v1/carts';


@Injectable({
  providedIn: 'root'
})
export class CartbucketService {
  
  private cartQtySubject = new BehaviorSubject<number>(0);
  private cartSubject = new BehaviorSubject<CartItem[]>([]);
  isLoggedIn$ = this.cartSubject.asObservable();
  cartQty$ = this.cartQtySubject.asObservable();
  cartItems: CartItem[] = [];
  cartItems$ = this.cartSubject.asObservable();

   
  constructor(private http: HttpClient) {
    this.loadData();
  }

  findall(): Observable<any> {  
    return this.http.get<any>(`${baseUrl}/all`);
  }

  loadData() {
    
    this.findall().subscribe({
      next: (x) => {
        console.log(this.cartItems,'Lince in cartbucket servce 39',x)
        this.cartItems = x;
        console.log(this.cartItems,'Lince in cartbucket servce 39')
        if (x.length > 0) {
          x.forEach(cart => {
            cart.value=cart.qty*cart.price;
          //  cart.cgst=cart.value*0.05;
        //    cart.sgst=cart.value*0.05;
          });
          this.cartSubject.next(x)
          this.cartQtySubject.next(x.length)
        } else {
          this.cartSubject.next(null)
          this.cartQtySubject.next(0)
        }
         
      },
      error: (err: Error) =>
        console.error('Fetching Cart Buckets Lists got an error: ' + err),
      complete: () => {
        
      }
    });
  }

  
  addToCart(cartItem: CartItem): Observable<ResultModel> {
    return this.http.post<ResultModel>(`${baseUrl}/`,cartItem);
  }

  updateCart(cartItem: CartItem): Observable<ResultModel> {
    return this.http.post<ResultModel>(`${baseUrl}/update`,cartItem);
  }

  removeFromCart(cartItem: CartItem): Observable<ResultModel> {
    return this.http.post<ResultModel>(`${baseUrl}/remove`,cartItem);
  }
  updateaddress(address:Address):Observable<ResultModel> {
    return this.http.post<ResultModel>(`${baseUrl}/updateaddress`,address);
  }
    

  checkout(orderplace :Orderplace): Observable<any> {
    return this.http.post<any>(`${baseUrl}/checkout`,orderplace);
  }

  paymentcheck(payment:Payment): Observable<any> {
    return this.http.post<any>(`${baseUrl}/paymentcheck`,payment);
  }

}
