<div class="flex-auto flex items-center container">

  <a class="action flex-none" href="https://wwww.successpublishers.in" id="get-vex" mat-flat-button>
    &copy;2024 Success Publishers
  </a>
  <div class="font-medium ltr:ml-4 rtl:mr-4 hidden sm:block">
    <button mat-button (click)="openDialog()">About</button><button mat-button (click)="openDialogTerms()">
      Terms &amp; Conditions </button>
    <button mat-button (click)="openDialogCookie()"> Cookie Policy</button>
    <button mat-button (click)="openDialogPrivacy()"> Privacy Policy</button>
    <button mat-button (click)="openDialogRefund()"> Refund Policy</button>

  </div>
</div>