<h2 mat-dialog-title>Terms &amp; Conditions</h2>
<mat-dialog-content class="mat-typography">

    <p class="text-justify">These Terms & Conditions govern your use of the Success Publishers website
        (successpublishers.in) and your purchase of books and related products from us. By accessing our website or
        making a purchase, you agree to be bound by these Terms & Conditions. Please read them carefully before
        proceeding</p>

    <h2 class="font-bold">General</h2>

    <ol class="list-inside  list-disc text-justify">
        <li>Acceptance: By using our website or making a purchase, you agree to comply with these Terms & Conditions. If
            you do not agree with any part of these Terms, you must not use our website or purchase products from us.
        </li>

        <li>Eligibility: You must be at least 18 years old to make a purchase from Success Publishers. By placing an
            order, you represent that you are of legal age to enter into a binding contract.</li>
    </ol>
    <h2 class="font-bold">Ordering and Payment</h2>
    <ol class="list-inside list-disc text-justify">
        <li>Product Availability: All orders are subject to product availability. We reserve the right to limit the
            quantity of products available for purchase.</li>

        <li>Order Acceptance: Your order constitutes an offer to purchase products from us. We reserve the right to
            accept or reject any order for any reason, including but not limited to product availability, errors in
            pricing or product descriptions, or suspicion of fraudulent activity.</li>

        <li>Payment: Payment for orders must be made in full at the time of purchase. We accept payment via
            successpublishers.in payment portal, and all transactions are processed securely. At present we <b> do not
                accept Cash-on-Delivery</b></li>
    </ol>
    <h2 class="font-bold">Shipping and Delivery</h2>
    <ol class="list-inside list-disc text-justify">
        <li>Shipping: We offer shipping to addresses Pan-India and International destinations. Shipping fees and
            delivery times may vary depending on your location and the shipping method selected at checkout.</li>

        <li>Delivery: We will make reasonable efforts to deliver your order within the estimated delivery time frame.
            However, we cannot guarantee delivery dates and are not liable for any delays caused by shipping carriers or
            other factors beyond our control.</li>
    </ol>
    <h2 class="font-bold">Estimated delivery timings</h2>
    <ol class="list-inside list-decimal text-justify">
        <li>Tamil Nadu - 1 to 2 working days.</li>
        <li>Other states - 3 to 4 working days,</li>
        <li>International destinations - 2 to 4 weeks </li>
    </ol>
    <h2 class="font-bold">Returns and Refunds</h2>
    <ol class="list-inside list-decimal text-justify">
        <li>Returns: If you are not satisfied with your purchase, you may return it for a refund within 3 days of
            receipt, subject to our Return Policy.</li>
        <li>Refunds: Refunds will be issued to the original method of payment and may take 10 to 15 days to process.
            Shipping fees are non-refundable, and you are responsible for return shipping costs unless the return is due
            to our error or a defective product.</li>
       
    </ol>
    <h2 class="font-bold">Intellectual Property</h2>
    <ol class="list-inside list-decimal text-justify">
        <li>Copyright: All content on the Success Publishers website, including text, graphics, logos, images, and
            software, is the property of Success Publishers or its licensors and is protected by copyright laws</li>
        <li>Use of Content: You may access and use the content on our website for personal, non-commercial purposes
            only. Any unauthorized use, reproduction, or distribution of our content is strictly prohibited.</li>
       
    </ol>
    <h2 class="font-bold">Limitation of Liability</h2>
    <ol class="list-inside list-disc text-justify">
        <li>Disclaimer: The information, products, and services provided by Success Publishers are provided on an "as
            is" and "as available" basis without any warranties, express or implied. We make no representations or
            warranties regarding the accuracy, completeness, or reliability of the information on our website.</li>
        <li>Limitation of Liability: In no event shall Success Publishers be liable for any indirect, incidental,
            special, consequential, or punitive damages arising out of or related to your use of our website or the
            products purchased from us</li>
         
        </ol>
        <h2 class="font-bold">Governing Law</h2>
        <ol class="list-inside list-disc text-justify">
            <li>These Terms & Conditions shall be governed by and construed in accordance with the laws of Madras High court
                (Chennai Jurisdiction). Any dispute arising out of or relating to these Terms shall be resolved exclusively
                in the courts of Madras High court (Chennai Jurisdiction)</li>
            
            </ol>




</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Close</button>

</mat-dialog-actions>