import { Component, OnDestroy, OnInit,inject,ChangeDetectionStrategy } from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { AboutComponent } from './about/about.component';
import { TermsComponent } from './termsconditions/terms.component';
import { CookiepolicyComponent } from './cookiepolicy/cookiepolicy.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { RefundpolicyComponent } from './refundpolicy/refundpolicy.component';

@Component({
  selector: 'vex-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [MatButtonModule, MatIconModule,MatDialogModule]
})
export class FooterComponent implements OnInit, OnDestroy {
  readonly dialog = inject(MatDialog);

  openDialog() {
    const dialogRef = this.dialog.open(AboutComponent);
     
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
  openDialogTerms() {
    const dialogTerms= this.dialog.open(TermsComponent);
    dialogTerms.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
  constructor() {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  openDialogCookie() {
    const dialog= this.dialog.open(CookiepolicyComponent);
    dialog.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  openDialogPrivacy() {
    const dialog= this.dialog.open(PrivacypolicyComponent);
    dialog.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  openDialogRefund() {
    const dialog= this.dialog.open(RefundpolicyComponent);
    dialog.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
