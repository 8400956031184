import { inject } from '@angular/core';
import { HttpRequest, HttpHandlerFn } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AccountService } from '../_services';

export function errorInterceptor(request: HttpRequest<any>, next: HttpHandlerFn) {
    const accountService = inject(AccountService);
    return next(request).pipe(catchError(err => {
        if (!accountService.userValue) {
            const isApiUrl = request.url.includes('/login/');
            const isApiUrl2 = request.url.includes('/verify')
            if (!isApiUrl2) {
                if (!isApiUrl) {
                    accountService.logout();
                }
            }


        }
        if ([401, 403, 500, 504].includes(err.status) && accountService.userValue) {
            // auto logout if 401 or 403 response returned from api
            ///accountService.logout2();
        }

        const error = err.error?.message || err.statusText;
        console.error(err);
        return throwError(() => error);
    }))
}
