<h2 mat-dialog-title>About Success Publishers</h2>
<mat-dialog-content class="mat-typography">

    <p class="text-justify">Success Publishers is a leading online bookstore specializing in the sale of law books. With a passion
        for legal education and a commitment to serving the needs of legal professionals, students, and enthusiasts, we
        provide a comprehensive selection of
        legal literature to support your academic and professional endeavors.</p>

    <h2 class="font-bold">Our Mission</h2>
    <p class="text-justify">At Success Publishers, our mission is to facilitate access to high-quality legal resources that empower
        individuals to excel in their legal studies, research, and practice. We strive to be a trusted partner for legal
        professionals, offering a diverse
        range of publications that cater to various fields of law and legal disciplines.</p>

        <h2 class="font-bold">Our Collection</h2>
    <p class="text-justify">We boast an extensive collection of law books covering a wide spectrum of topics, including but not
        limited to:</p>
        <ol class="list-inside list-decimal text-justify">
            <li>Constitutional Law</li>
            <li>Criminal Law</li>
            <li>Business Law</li>
            <li>International Law</li>
            <li>Intellectual Property Law</li>
            <li>Environmental Law</li>
            <li>Family Law</li>
            <li>Legal Ethics and much more</li>
        </ol>
    
    <p class="text-justify">Whether you're a law student seeking textbooks for your courses, a practicing attorney in need of reference
    materials, or a legal enthusiast
    exploring new areas of interest, we have something for everyone.
    </p>

    <h2 class="font-bold">Why Choose Us?</h2>
    <ol class="list-inside list-decimal text-justify">
        <li>Comprehensive Selection: We curate our collection to include the latest editions and authoritative works from renowned legal scholars and
            publishers, ensuring that you have access to the most relevant and reliable resources.</li>
        <li>Convenience: Our user-friendly website allows you to browse, search, and purchase books with ease. With just a few clicks, you can find the
            titles you need and have them delivered right to your doorstep.</li>
        <li>Exceptional Service: Customer satisfaction is our top priority. Our dedicated team is committed to providing prompt assistance, answering
            your inquiries, and addressing any concerns you may have throughout your shopping experience.</li>
        <li>Competitive Pricing: We strive to offer competitive prices on all our products, making quality legal literature accessible and affordable
            for all.</li>
    </ol>

    <h2 class="font-bold">Contact Us</h2>

    <p class="text-justify">Have a question or need assistance? Feel free to reach out to our customer support team at
        info&#64;successpublishers.in. We're here to help you find the right books for your needs</p>
<p class="text-justify"> Thank you for choosing Success Publishers as your trusted source for law books. We look forward to serving you and helping you achieve success in your legal journey. </p>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Close</button>

</mat-dialog-actions>