import { Injectable, signal } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ResultModel, User } from '@app/_models';
//import { environment } from '@environments/environment';
const baseUrl = '/api/v1/auth';


interface Userjh {
  username: string;
  isLoggedIn: boolean;
}

@Injectable({ providedIn: 'root' })
export class AccountService {
  private refreshTokenTimeout: string | number | NodeJS.Timeout | undefined;
  private userSubject: BehaviorSubject<User | null>;
  public user: Observable<User | null>;

  public authenticated = signal<boolean>(false);
  private userSubjectjh = new BehaviorSubject<Userjh>({ username: '', isLoggedIn: false });
  isLoggedIn$ = this.userSubjectjh.asObservable();
  constructor(
    private router: Router,
    private http: HttpClient
  ) {
    this.userSubject = new BehaviorSubject(
      JSON.parse(localStorage.getItem('olb-user')!)
    );
    this.user = this.userSubject.asObservable();




  }

  public get userValue() {
    return this.userSubject?.value;
  }

  login(username: string, password: string) {
    return this.http
      .post<User>(`${baseUrl}/signin`, { username, email: username, password })
      .pipe(
        map((user) => {
          console.log('Line 41 login service', user)
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('olb-user', JSON.stringify(user));
          this.userSubject.next(user);
          if (user) {
            this.userSubjectjh.next({ username, isLoggedIn: true });
            this.authenticated.set(true)
            this.startRefreshTokenTimer();
          }

          return user;
        })
      );
  }

  logoutorig() {
    // remove user from local storage and set current user to null
     
    this.stopRefreshTokenTimer();
    this.userSubject.next(null);

    this.router.navigate(['/login']);
    localStorage.removeItem('olb-user');
    //  this.userSubject.next(null);
    //  this.stopRefreshTokenTimer();
    //  this.router.navigate(['/login']);
  }


  logout() {
    // remove user from local storage and set current user to null
    this.http
      .post<any>(`${baseUrl}/revoke-token`, {}, { withCredentials: true })
      .subscribe({
        next: (x) => {
          this.userSubjectjh.next({ username: '', isLoggedIn: false });
          this.authenticated.set(false)
          //    this.ref.detectChanges();
        },
        error: (err: Error) => {
          this.router.navigate(['/login']);
        },
        complete: () => {

        },
      });
    this.stopRefreshTokenTimer();
    this.userSubject.next(null);

    this.router.navigate(['/']);
    localStorage.removeItem('olb-user');
    //  this.userSubject.next(null);
    //  this.stopRefreshTokenTimer();
    //  this.router.navigate(['/login']);
  }
  logout2() {
    // remove user from local storage and set current user to null

    this.stopRefreshTokenTimer();
    this.userSubject.next(null);
    this.router.navigate(['/']);
   
  }

  register(signUpRequest: User): Observable<any> {
    return this.http.post<any>(`${baseUrl}/register`, signUpRequest);
  }

  getAll() {
    return this.http.get<User[]>(`${baseUrl}/users`);
  }

  getById(id: string) {
    return this.http.get<User>(`${baseUrl}/users/${id}`);
  }
  verifyEmail(token: string): Observable<any> {
    return this.http.post<any>(`${baseUrl}/verify-email`, { token });
  }

  forgotPassword(email: string): Observable<ResultModel> {
    return this.http.post<ResultModel>(`${baseUrl}/forgot-password`, { email });
  }

  validateResetToken(token: string) {
    return this.http.post(`${baseUrl}/validate-reset-token`, { token });
  }

  resetPassword(token: string, password: string, confirmPassword: string): Observable<ResultModel> {
    return this.http.post<ResultModel>(`${baseUrl}/reset-password`, {
      token,
      password,
      confirmPassword
    });
  }
  update(id: string, params: any) {
    return this.http.put(`${baseUrl}/users/${id}`, params).pipe(
      map((x) => {
        // update stored user if the logged in user updated their own record
        if (id == this.userValue?.id) {
          // update local storage
          const user = { ...this.userValue, ...params };
          localStorage.setItem('olb-user', JSON.stringify(user));

          // publish updated user to subscribers
          this.userSubject.next(user);
        }
        return x;
      })
    );
  }

  delete(id: string) {
    return this.http.delete(`${baseUrl}/users/${id}`).pipe(
      map((x) => {
        // auto logout if the logged in user deleted their own record
        if (id == this.userValue?.id) {
          this.logout();
        }
        return x;
      })
    );
  }

  getUser(): any {
    return this.http.get<any>(`${baseUrl}/user`, { withCredentials: true });
  }
  private startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    let acceeToken1 = this.userValue?.accessToken;
    if (acceeToken1 == null) {
      acceeToken1 = '';
    }
    const accessToken = JSON.parse(atob(acceeToken1?.split('.')[1]));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(accessToken.exp * 1000);
    console.log('expires', expires);
    console.log(' expires.getTime()', expires.getTime());
    console.log('  Date.now() ', Date.now());
    const timeout = expires.getTime() - Date.now() - 60 * 1000;
    console.log('timeout', timeout);
    this.refreshTokenTimeout = setTimeout(
      () => this.refreshToken().subscribe(),
      timeout
    );
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  refreshTokenCheck() {
    console.log('user storage', this.user)
    if (!this.user) {
      console.log('No user')
      return;
    }

    let acceeToken1 = this.userValue?.accessToken;
    if (!acceeToken1 ) {
      acceeToken1 = '';
      return;
    }
    console.log('user acceeToken1',acceeToken1)
    if (acceeToken1 == null) {
      acceeToken1 = '';
    }
    const accessToken = JSON.parse(atob(acceeToken1?.split('.')[1]));
    console.log('user storage', this.user)
    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(accessToken.exp * 1000);
    console.log('expires', expires);
    console.log(' expires.getTime()', expires.getTime());
    console.log('  Date.now() ', Date.now());
    const timeout = expires.getTime() - Date.now() - 60 * 1000;
    console.log('timeout', timeout);
    if(timeout<0){
      return;
    }
    return this.http
      .post<any>(`${baseUrl}/refresh-token`, {}, { withCredentials: true })
      .pipe(
        map((account) => {
          this.userSubject.next(account);
          this.user = this.userSubject.asObservable();
          if (account) {
            console.log('parsed value',account.email);
            localStorage.setItem('olb-user', JSON.stringify(account));
          this.userSubject.next(account);
        
            this.userSubjectjh.next({ username: account.email, isLoggedIn: true });
            this.authenticated.set(true)
            this.startRefreshTokenTimer();
         
            this.startRefreshTokenTimer();
          } else {
            this.stopRefreshTokenTimer();
            localStorage.removeItem('olb-user');
            // this.logout();
               this.router.navigate(['/']);
          }

          return account;
        })
      );
  }
  refreshTokenv() {
    console.log('user storage', this.user)
    if (!this.user) {
      console.log('No user')
      return;
    }

    let acceeToken1 = this.userValue?.accessToken;
    console.log('No user',acceeToken1);
     
    console.log('user acceeToken1',acceeToken1)
    if (acceeToken1 == null) {
      acceeToken1 = '';
    }
    const accessToken = JSON.parse(atob(acceeToken1?.split('.')[1]));
    console.log('user storage', this.user)
    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(accessToken.exp * 1000);
    console.log('expires', expires);
    console.log(' expires.getTime()', expires.getTime());
    console.log('  Date.now() ', Date.now());
    const timeout = expires.getTime() - Date.now() - 60 * 1000;
    console.log('timeout', timeout);
    if(+timeout<0){
      localStorage.removeItem('olb-user');
      return;
    }else{
      console.log('continue', timeout);
    }
    return this.http
      .post<any>(`${baseUrl}/refresh-token`, {}, { withCredentials: true })
      .pipe(
        map((account) => {
          this.userSubject.next(account);
          this.user = this.userSubject.asObservable();
          if (account) {
            this.startRefreshTokenTimer();
          } else {
            this.stopRefreshTokenTimer();
            // this.logout();
            //  this.router.navigate(['/']);
          }

          return account;
        })
      );
  }
  refreshToken() {
    return this.http
      .post<any>(`${baseUrl}/refresh-token`, {}, { withCredentials: true })
      .pipe(
        map((account) => {
          console.log('refreshToken',account);
         
          if (account) {
            this.userSubject.next(account);
            this.user = this.userSubject.asObservable();
            this.userSubjectjh.next({ username: account.email, isLoggedIn: true });
            this.authenticated.set(true)
            this.startRefreshTokenTimer();
          } else {
            localStorage.removeItem('olb-user');
            this.userSubject.next(null);
            this.stopRefreshTokenTimer();
            // this.logout();
            //  this.router.navigate(['/']);
          }

          return account;
        })
      );
  }
}
