import { inject } from '@angular/core';
import { HttpRequest, HttpHandlerFn } from '@angular/common/http';

import { AccountService } from '@app/_services';

export function jwtInterceptor(request: HttpRequest<any>, next: HttpHandlerFn) {
    // add auth header with jwt if user is logged in and request is to the api url
    const accountService = inject(AccountService);
    const user = accountService.userValue;
    const isLoggedIn = user?.accessToken;
    const isApiUrl = request.url.includes('/api/')    //startsWith(environment.apiUrl);
    const isApiUrl2 = request.url.includes('/verify')    //startsWith(environment.apiUrl);
    if (!isApiUrl2) {
        if (isLoggedIn && isApiUrl) {
            request = request.clone({
                setHeaders: { Authorization: `Bearer ${user.accessToken}` }
            });
        }
    }

    //http://localhost:4200/verify?token=MwaJaq61LqHTXv7r8OCqvAuqEXWIGt79o7Evba2oc5CHVN4zT1u99eDn5twZMPnyzZGApJd64qgIvfJ5W8MV82EtcUMYXjTnBHJKbXheizTIjnztfgJ7tGYNhm6flvIG
    return next(request);
}