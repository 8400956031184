<h2 mat-dialog-title>Success Publishers Refund Policy </h2>
<mat-dialog-content class="mat-typography">

    <p class="text-justify">At Success Publishers, we strive to ensure your satisfaction with every purchase. If you
        need to cancel an order or return a product, please review our Return & Cancellation Policy below for guidance.
    </p>
    <h2 class="font-bold">Order Cancellation</h2>
    <ol class="list-inside  list-disc text-justify">
        <li>Cancellation Requests: You may request to cancel your order within 24 hours of placing it. To cancel an
            order, please contact our customer support team as soon as possible with your order details.
        </li>
        <li>
            Accepted Cancellations: We will make reasonable efforts to accommodate cancellation requests made within the
            specified timeframe. However, we cannot guarantee that all cancellation requests will be successful,
            especially if the order has already been processed or shipped.
        </li>
    </ol>
    <h2 class="font-bold">Returns</h2>
    <ol class="list-inside list-disc text-justify">
        <li>Return Eligibility: You may return products purchased from Success Publishers within 3 days of receipt for a
            refund or exchange, subject to the conditions outlined below.
        </li>
        <li>
            Return Conditions: To be eligible for a return, the product must be unused, in its original condition, and
            in the original packaging. Books with noticeable signs of use or damage may not be eligible for return.
        </li>
        <li>
            Return Process: To initiate a return, please contact our customer support team to request a return
            authorization and instructions. You will be responsible for return shipping costs unless the return is due
            to our error or a defective product.

        </li>



    </ol>

    <h2 class="font-bold">Refund Eligibility</h2>
    <ol class="list-inside list-decimal text-justify">
        <li>Once your return is received and inspected, we will notify you of the approval or rejection of your refund.
            Refunds are issued for the purchase price of the product(s) returned, excluding shipping fees.
        </li>

    </ol>
    <h2 class="font-bold">Refund Processing</h2>
    <ol class="list-inside list-decimal text-justify">
        <li>Refunds will be processed within 10 to 15 days of approval and credited to the original method of payment.
            Please note that it may take additional time for the refund to reflect in your account, depending on your
            financial institution.
        </li>

    </ol>
    <h2 class="font-bold">Exceptions</h2>
    <ol class="list-inside list-decimal text-justify">
        <li>Special Circumstances: In certain cases, such as defective or damaged products, we may offer alternative
            solutions or accommodations beyond our standard return and refund policy. Please contact our customer
            support team for assistance in such situations.
        </li>

    </ol>
    <h2 class="font-bold">Contact Us</h2>
    <ol class="list-inside list-decimal text-justify">
        <li>If you have any questions, concerns, or requests regarding this Cookie Policy or our use of cookies, please
            contact us at info[at]successpublishers.in</li>

    </ol>
    <p class="font-bold  text-justify">If you have any questions or concerns about our Return & Refund Policy, or if you
        need assistance with a return or cancellation request, please contact our customer support team at
        info&#64;successpublishers.in.
    </p>
    <p>
        Thank you for shopping with Success Publishers!</p>


</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Close</button>

</mat-dialog-actions>